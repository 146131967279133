import React from 'react'
import {NormalField} from 'reactstrap-form-fields'
import { Button } from 'reactstrap'

export default () => (
  <form name="Contact Us" netlify-honeypot="bot-field" data-netlify="true">
    <input type="hidden" name="bot-field" />
    <NormalField name="Full Name" required={true}/>
    <NormalField name="Email" required={true}/>
    <NormalField name="Phone Number"/>
    <NormalField name="Comments" type="textarea"/>
    <Button className="btn-block">Submit</Button>
  </form>
)

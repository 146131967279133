import React from "react"
import PageTemplate from '../components/pageTemplate'
import { graphql } from 'gatsby'
import Form from '../components/form'
import {Row, Col} from 'reactstrap'

export default ({data}) => (
  <PageTemplate title="Distributors" img={data.fileName} distributorCta={false}>
    <h5>Become a Raybek Foods Distributor!</h5>
    <p>Raybek Foods has received an overwhelming response from customers all around Australia who love our famous yoghurt and gourmet products. In order to meet this demand, Raybek Foods works closely with its Chilled logistics partners to ensure efficient and effective results Australia wide. The Raybek Foods range is ideal for distributors looking to expand their range, existing delivery runs or wholesalers.</p>
    <p>Expanding this network is a priority for us at Raybek Foods and if you believe your business could contribute to this we would love to hear from you.</p>
    <Row className="d-flex justify-content-center py-4">
      <Col md="8">
        <h4 className="text-center">Contact Us</h4>
        <hr className="primary"/>
        <Form/>
      </Col>
    </Row>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
